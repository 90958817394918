<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog">
    <v-card>
      <v-card-title primary-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form :ref="formRef">
          <v-text-field
            v-model="form.poliza"
            name="polizaCod"
            label="Código"
            placeholder="Escriba el Código..."
            :rules="[(v) => !!v || 'El campo Código es requerido.']"
            @keyup="form.poliza = codeFormat(form.poliza)"
          ></v-text-field>

          <v-text-field
            v-model="form.poliza_descripcion"
            name="polizaDesc"
            label="Póliza"
            placeholder="Escriba nombre de la Póliza..."
            id="id_polizaDesc"
            :rules="[(v) => !!v || 'El campo descripción es requerido.']"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit">{{ labels.submit }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";
export default {
  name: "PolizaFormModal",
  mixins: [FormMixin],
  data() {
    return {
      defaultTitle: "Formulario de Poliza",
      form: {
        poliza: "",
        poliza_descripcion: "",
      },
    };
  },
};
</script>
