<template>
  <v-container>
    <v-card>
      <v-data-table v-bind="bind.table" :search="search">
        <template #top>
          <div class="d-flex pa-3">
            <v-text-field v-model="search" v-bind="bind.search"></v-text-field>
            <v-divider v-bind="bind.divider"></v-divider>
            <v-spacer></v-spacer>
            <v-btn v-bind="bind.refresh" v-on="on.refresh">
              <v-icon>{{ icons.refresh }}</v-icon></v-btn
            >
            <v-btn v-bind="bind.new" v-on="on.new">{{ labels.new }}</v-btn>
          </div>
        </template>

        <template #item.actions="{ item }">
          <v-btn v-bind="bind.actions" v-on="on.edit(item)">
            <v-icon>{{ icons.edit }}</v-icon>
          </v-btn>
          <v-btn color="error" v-bind="bind.actions" v-on="on.delete(item)">
            <v-icon>{{ icons.delete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <Modal v-model="dialog" v-bind="bind.form" v-on="on.form" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import Modal from "@/components/Modal/ModalProduct/Modal-Poliza";
import PageMixin from "@/Mixins/SetPageMixin.js";

export default {
  name: "PolizasCrudPage",
  mixins: [PageMixin],
  components: {
    Modal,
  },
  data: () => ({
    headers: [
      { text: "Código", value: "poliza", align: "left" },
      { text: "Descripción", value: "poliza_descripcion", align: "left" },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
    polizas: [],
  }),

  computed: {
    items() {
      return this.polizas;
    },
  },

  methods: {
    ...mapActions("NewProduct", [
      "getPolizas",
      "CrearPoliza",
      "UpdatePoliza",
      "deletePoliza",
    ]),

    async setup() {
      this.loading = true;
      try {
        const response = await this.getPolizas();
        this.polizas = response;
      } catch (error) {
        this.snackbar(error.info);
      }
      this.loading = false;
    },

    saveMethod() {
      return this.CrearPoliza;
    },
    updateMethod() {
      return this.UpdatePoliza;
    },
    deleteMethod() {
      return this.deletePoliza;
    },
  },
};
</script>
